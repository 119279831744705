._icon {
  margin: 0 6px;
}
._icon:hover {
  color: black;
  cursor: pointer;
}
._user_profile {
  position: absolute;
  top: 0%;
  right: 0%;
  height: 100%;
  width: 340px;
  padding: 10px 15px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  color: white;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.tabs {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.tab {
  flex: 1;
  padding: 10px 0;
  color: black;
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  border-radius: 5px 5px 0 0;
  transition: background-color 0.3s ease;
  background-color: transparent;
}

.tab.active {
  color: #289f81;
  border-bottom: 1px solid #289f81;
}

.tab:hover {
  color: #17c69a;
}

.tab-content {
  flex-grow: 1;
  background: white;
  color: black;
  border-radius: 0 0 5px 5px;
}
.tab-content .tab-summary-btn{
cursor: pointer;
padding: 5px 10px;
border-radius: 20px;
background-color: #119574;
color: #fff;
display: flex;
align-items: center;

}
.tab-content .tab-summary-btn:hover{
background-color: #076850;

}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 2s linear infinite;
}
