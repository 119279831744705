#task-tbl_wrapper {
    overflow-x: auto;
    white-space: nowrap; /* Prevents wrapping of table cells */
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    font-size: 12px;
  }
  
  .table th,
  .table td {
    padding: 10px;
    text-align: left;
    font-size: 12px;

  }
  
  @media (max-width: 768px) {
    .table thead {
      display: none;
    }
  
    .table tr {
      display: block;
      margin-bottom: 10px;
    }
  
    .table td {
      display: block;
      text-align: right;
      border-bottom: 1px solid #ddd;
      position: relative;
      padding-left: 50%;
    }
  
    .table td::before {
      content: attr(data-label); /* Use the "data-label" attribute to label each cell */
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 10px;
      font-weight: bold;
      text-align: left;
    }
  }
  